import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import authService from '../services/auth.service';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    // Auth
    {
        path: '/login',
        name: 'auth.login',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
        beforeEnter: (to, from, next) => {
            //store.commit("setAPI", 'http://213.14.184.247:8100/v1/website/');
            //store.commit("setAPI", 'http://localhost:8443/v1/website/');
            // store.commit("setAPI", 'https://globalapi.tfcapital.me:8443/v1/website/');
            store.commit('setAPI', 'https://selfserviceapi.dynamicmarkets.com/v1/website/');
            // store.commit("setAPI", 'https://api.incodifintech.com/v1/website/');
            next();
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' },
        beforeEnter: (to, from, next) => {
            //store.commit("setAPI", 'http://213.14.184.247:8100/v1/website/');
            //store.commit("setAPI", 'http://localhost:8443/v1/website/');
            // store.commit("setAPI", 'https://globalapi.tfcapital.me:8443/v1/website/');
            store.commit('setAPI', 'https://selfserviceapi.dynamicmarkets.com/v1/website/');
            // store.commit("setAPI", 'https://api.incodifintech.com/v1/website/');
            next();
        },
    },
    {
        path: '/verify-email',
        name: 'auth.verify_email',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/email_verify.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/mail-verification',
        name: 'auth.mail-verification',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/verify_mail.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/verify-device',
        name: 'auth.verify_device',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/device_verify.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/recovery-password',
        name: 'auth.recovery_password',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/pass_recovery.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/reset-password',
        name: 'auth.reset_password',
        component: () => import(/* webpackChunkName: "index2" */ '../views/auth/reset_password.vue'),
        meta: { layout: 'auth' },
    },
    // Dashboard
    {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "index2" */ '../views/dashboard/index.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    // Account
    {
        path: '/account/profil',
        name: 'account.profil',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/account/Profil.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/account/kyc',
        name: 'account.kyc',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/account/KYC.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/account/withdraw',
        name: 'account.withdraw',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/account/Withdraw.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },

    {
        path: '/account/deposit',
        name: 'account.deposit',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/account/Deposit.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/account/crypto',
        name: 'account.crypto',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/account/Crypto.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },

    // Referral Program
    {
        path: '/referral-program/invite',
        name: 'referral_program.invite',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/referral_program/Invite.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/referral-program/statistics',
        name: 'referral_program.statistics',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/referral_program/Statistics.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/trading-accounts/accounts',
        name: 'trading-accounts.accounts',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/trading_accounts/Accounts.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/pages/faq.vue'),
        beforeEnter: (to, from, next) => {
            authService.verify(to, from, next);
        },
    },
    {
        path: '/:pathMatch(.*)*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/error404'),
        meta: { layout: 'auth' },
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeResolve((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
